<template>
    <div>
        <component :is="this.$route.meta.layout || 'div'">
            <router-view />
        </component>
    </div>
</template>

<script>
// import Nav from "./components/Nav.vue";
// import axios from "./axios";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(["user"]),
    },
    async created() {
        /* const response = await axios.get("user");
    this.$store.dispatch('user', response.data); */
        /*
        axios.interceptors.response.use(undefined, function (err) {
            return new Promise(function (resolve, reject) {
                if (
                    err.status === 401 &&
                    err.config &&
                    !err.config.__isRetryRequest
                ) {
                    // if you ever get an unauthorized, logout the user
                    localStorage.removeItem("token");
                    localStorage.removeItem("roles");
                    localStorage.removeItem("permissions");
                    this.$store.dispatch("user", null);
                    // you can also redirect to /login if needed !
                    caches.keys().then((cacheNames) => {
                        cacheNames.forEach((cacheName) => {
                            caches.delete(cacheName);
                        });
                    });
                    this.$toasted.show("Please Login to continue!");
                    this.$router.push("/login");
                }
                throw err;
            });
        }); */
    },
};
</script>

<style></style>
