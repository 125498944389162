<template>
    <!-- Page Wrapper -->
    <div id="wrapper">
        <!-- Sidebar -->
        <Sidebar />

        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">
            <!-- Main Content -->
            <div id="content">
                <!-- Start of Topbar -->
                <Topbar />
                <!-- End of Topbar -->

                <!-- Begin Page Content -->
                <div class="container-fluid">
                    <router-view></router-view>
                </div>
                <!-- /.container-fluid -->
            </div>
            <!-- End of Main Content -->

            <!-- Footer -->
            <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span
                            >Copyright &copy; {{ copyYear }} {{ $appName }}
                            {{ appVersion }}</span
                        >
                    </div>
                </div>
            </footer>
            <!-- End of Footer -->
        </div>
        <!-- End of Content Wrapper -->
    </div>
    <!-- End of Page Wrapper -->

    <!-- Scroll to Top Button-->

    <!-- Logout Modal-->
</template>

<script>
import sbadmin2 from "../../../sb-admin-2.js";
// import axios from "axios";
import { mapGetters } from "vuex";
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";

export default {
    name: "AdminLayout",
    components: {
        Topbar,
        Sidebar,
    },
    data() {
        return {
            appName: this.$appName,
            appVersion: this.$appVersion,
            startYear: "",
            copyYear: "",
            currentYear: "",
        };
    },
    mounted() {
        sbadmin2.init();
        console.log("App Name:", this.$appName);
        this.getCopyright();
    },
    computed: {
        ...mapGetters(["user"]),
    },
    methods: {
        getCopyright() {
            let d = new Date();
            this.currentYear = d.getFullYear();
            this.startYear = 2022;

            if (this.currentYear > this.startYear) {
                this.copyYear = this.startYear + "-" + this.currentYear;
            } else {
                this.copyYear = this.startYear;
            }
        },
    },
};
</script>
