import axios from "axios";

let headers = {
    "cache-control": "no-cache",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
};
let accessToken = localStorage.getItem("token");

if (accessToken && accessToken !== "") {
    headers.Authorization = `Bearer ${accessToken}`;
}

export default axios.create({
    baseURL:
        process.env.NODE_ENV === "development"
            ? "http://localhost/liquorin/core/api"
            : "https://liquorin.tpdb.in/core/api/",
    headers: headers,
});
