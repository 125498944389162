<template>
    <ul
        class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
    >
        <!-- Sidebar - Brand -->
        <router-link
            class="sidebar-brand d-flex align-items-center justify-content-center"
            to="/"
        >
            <div class="sidebar-brand-icon rotate-n-15">
                <!-- <i class="fas fa-laugh-wink"></i> -->
                <i class="fas fa-wine-glass"></i>
            </div>
            <div class="sidebar-brand-text mx-3">{{ APP_NAME }}</div>
        </router-link>

        <!-- Divider -->
        <hr class="sidebar-divider my-0" />

        <!-- Nav Item - Dashboard -->
        <li class="nav-item" :class="{ active: this.$route.name === 'admin' }">
            <router-link class="nav-link" to="/admin">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <span>Dashboard</span></router-link
            >
        </li>
        <div v-if="roles.has('superadmin')">
            <!-- Divider -->
            <hr class="sidebar-divider" />
            <div class="sidebar-heading">Masters</div>
            <li class="nav-item">
                <a
                    class="nav-link collapsed"
                    href="#"
                    data-toggle="collapse"
                    data-target="#mastersManage"
                    aria-expanded="true"
                    aria-controls="mastersManage"
                >
                    <i class="fas fa-fw fa-crown"></i>
                    <span>Masters Management</span>
                </a>
                <div
                    id="mastersManage"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSidebar"
                >
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Masters</h6>
                        <router-link
                            class="collapse-item"
                            to="/admin/masters/category"
                            >Category</router-link
                        >
                        <router-link
                            class="collapse-item"
                            to="/admin/masters/brand"
                            >Brand</router-link
                        >
                        <router-link
                            class="collapse-item"
                            to="/admin/masters/supplier"
                            >Supplier</router-link
                        >
                        <router-link
                            class="collapse-item"
                            to="/admin/masters/taxes"
                            >Taxes</router-link
                        >
                        <router-link
                            class="collapse-item"
                            to="/admin/masters/items"
                            >Item Master</router-link
                        >
                    </div>
                </div>
            </li>
        </div>

        <div v-if="roles.has('superadmin') || roles.has('admin')">
            <!-- Divider -->
            <hr class="sidebar-divider" />
            <div class="sidebar-heading">Companies</div>
            <li class="nav-item">
                <a
                    class="nav-link collapsed"
                    href="#"
                    data-toggle="collapse"
                    data-target="#companyManage"
                    aria-expanded="true"
                    aria-controls="companyManage"
                >
                    <i class="fas fa-fw fa-building"></i>
                    <span>Company Management</span>
                </a>
                <div
                    id="companyManage"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSidebar"
                >
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Companies</h6>
                        <router-link class="collapse-item" to="/admin/company"
                            >All Companies</router-link
                        >
                        <!-- <router-link class="collapse-item" to="/admin/company"
                          >Company</router-link
                      > -->
                    </div>
                </div>
            </li>
        </div>

        <div v-if="roles.has('superadmin') || roles.has('admin')">
            <!-- Divider -->
            <hr class="sidebar-divider" />
            <div class="sidebar-heading">Outlets</div>
            <li class="nav-item">
                <a
                    class="nav-link collapsed"
                    href="#"
                    data-toggle="collapse"
                    data-target="#outletManage"
                    aria-expanded="true"
                    aria-controls="outletManage"
                >
                    <i class="fas fa-fw fa-hotel"></i>
                    <span>Outlets Management</span>
                </a>
                <div
                    id="outletManage"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSidebar"
                >
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Outlets</h6>
                        <router-link
                            class="collapse-item"
                            to="/admin/company/outlet"
                            >All Outlets</router-link
                        >
                    </div>
                </div>
            </li>
        </div>

        <div v-if="roles.has('superadmin') || roles.has('admin')">
            <!-- Divider -->
            <hr class="sidebar-divider" />
            <div class="sidebar-heading">Outlet Items</div>
            <li class="nav-item">
                <a
                    class="nav-link collapsed"
                    href="#"
                    data-toggle="collapse"
                    data-target="#itemManage"
                    aria-expanded="true"
                    aria-controls="outletManage"
                >
                    <i class="fas fa-fw fa-shopping-basket"></i>
                    <span>Items Management</span>
                </a>
                <div
                    id="itemManage"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSidebar"
                >
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Outlet Items</h6>
                        <router-link
                            class="collapse-item"
                            to="/admin/outlet/items"
                            >Outlet Items</router-link
                        >
                    </div>
                </div>
            </li>
        </div>

        <div v-if="roles.has('superadmin') || roles.has('admin')">
            <!-- Divider -->
            <hr class="sidebar-divider" />
            <div class="sidebar-heading">Users</div>
            <li class="nav-item">
                <a
                    class="nav-link collapsed"
                    href="#"
                    data-toggle="collapse"
                    data-target="#userManage"
                    aria-expanded="true"
                    aria-controls="userManage"
                >
                    <i class="fas fa-fw fa-users"></i>
                    <span>User Management</span>
                </a>
                <div
                    id="userManage"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSidebar"
                >
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Users</h6>
                        <router-link class="collapse-item" to="/admin/users"
                            >All Users</router-link
                        >
                        <router-link
                            v-if="roles.has('superadmin')"
                            class="collapse-item"
                            to="/admin/users/access-tokens"
                            >Access Tokens</router-link
                        >
                    </div>
                </div>
            </li>
        </div>
        <div
            v-if="
                roles.has('superadmin') ||
                roles.has('admin') ||
                roles.has('user')
            "
        >
            <!-- Divider -->
            <hr class="sidebar-divider" />
            <div class="sidebar-heading">Stocks</div>
            <li class="nav-item">
                <a
                    class="nav-link collapsed"
                    href="#"
                    data-toggle="collapse"
                    data-target="#stockManage"
                    aria-expanded="true"
                    aria-controls="stockManage"
                >
                    <i class="fas fa-fw fa-cubes"></i>
                    <span>Stock Management</span>
                </a>
                <div
                    id="stockManage"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionSidebar"
                >
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Stocks</h6>
                        <router-link
                            class="collapse-item"
                            to="/admin/daily/stock"
                            >Daily Stock</router-link
                        >
                        <router-link
                            v-if="roles.has('superadmin') || roles.has('admin')"
                            class="collapse-item"
                            to="/admin/daily/stock/update"
                            >Update Daily Stock</router-link
                        >
                        <router-link
                            class="collapse-item"
                            to="/admin/stock/summary"
                            >Stock Summary</router-link
                        >
                        <router-link
                            v-if="roles.has('superadmin') || roles.has('admin')"
                            class="collapse-item"
                            to="/admin/stock/summary/update"
                            >Update Stock Summary</router-link
                        >
                    </div>
                </div>
            </li>
            <!-- Divider -->
            <hr class="sidebar-divider" />
            <div class="sidebar-heading">Reports</div>
            <li class="nav-item">
                <a
                    class="nav-link collapsed"
                    href="#"
                    data-toggle="collapse"
                    data-target="#reportManage"
                    aria-expanded="true"
                    aria-controls="reportManage"
                >
                    <i class="fas fa-fw fa-file-excel"></i>
                    <span>Report Management</span>
                </a>
                <div
                    id="reportManage"
                    class="collapse"
                    aria-labelledby="reportManage"
                    data-parent="#accordionSidebar"
                >
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Reports</h6>
                        <router-link
                            class="collapse-item"
                            to="/admin/report/datewise"
                            >Purchase & Sales</router-link
                        >
                        <router-link
                            class="collapse-item"
                            to="/admin/report/stock-in-liters"
                            >Closing Stock in Liters</router-link
                        >
                        <router-link
                            class="collapse-item"
                            to="/admin/report/consumption-in-liters"
                            >Consumption in Liters</router-link
                        >
                        <router-link
                            class="collapse-item"
                            to="/admin/report/stock-in-out"
                            >Stock In & Out</router-link
                        >
                        <router-link
                            class="collapse-item"
                            to="/admin/report/sales-stock-statement"
                            >Sales & Stock Statement</router-link
                        >
                    </div>
                </div>
            </li>
            <!-- Divider -->
            <hr v-if="roles.has('superadmin')" class="sidebar-divider" />
            <div v-if="roles.has('superadmin')" class="sidebar-heading">
                Settings
            </div>
            <li v-if="roles.has('superadmin')" class="nav-item">
                <a
                    class="nav-link collapsed"
                    href="#"
                    data-toggle="collapse"
                    data-target="#settingManage"
                    aria-expanded="true"
                    aria-controls="settingManage"
                >
                    <i class="fas fa-fw fa-cogs"></i>
                    <span>Setting Management</span>
                </a>
                <div
                    id="settingManage"
                    class="collapse"
                    aria-labelledby="settingManage"
                    data-parent="#accordionSidebar"
                >
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Settings</h6>
                        <router-link class="collapse-item" to="/admin/settings"
                            >Outlet Settings</router-link
                        >
                    </div>
                </div>
            </li>
        </div>

        <!-- Divider -->
        <hr class="sidebar-divider d-none d-md-block" />
        <!-- <li class="nav-item">
          <pre>{{ user }}</pre>
      </li> -->
        <!-- Sidebar Toggler (Sidebar) -->
        <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle"></button>
        </div>
        <div class="spacer-bottom">&nbsp;</div>
    </ul>
    <!-- End of Sidebar -->
</template>

<script>
import { mapGetters } from "vuex";
import Auth from "@/auth";
import $ from "jquery";
export default {
    name: "Sidebar",
    data() {
        return {
            current_user: {},
            roles: new Set(),
            permissions: new Set(),
            $auth: new Auth(JSON.parse(localStorage.getItem("vuex")).user),
            userRole: JSON.parse(localStorage.getItem("roles")) || [],
            userPermissions:
                JSON.parse(localStorage.getItem("permissions")) || [],
            isActive: false,
        };
    },
    computed: {
        ...mapGetters(["user"]),
    },
    created() {
        this.userRole.forEach((r) => {
            this.roles.add(r.name);
        });
        this.userPermissions.forEach((p) => {
            this.permissions.add(p.name);
        });
    },
    mounted() {
        this.checkPermissions();
    },
    methods: {
        checkPermissions() {
            console.log("SidebarUser", this.$auth);
            return new Auth(this.user);
        },
    },
    watch: {
        $route(to) {
            console.log("route to", to);
            // alert(this.$route.name);
            /**
             * Collapse dropdown on route change
             * @returns $route
             */
            if (to) {
                $(".collapse").each(function () {
                    if ($(this).hasClass("show")) {
                        setTimeout(() => {
                            $(this).parent().find(".nav-link").click();
                        }, 1000);
                        $(this)
                            .parent()
                            .find(".nav-link")
                            .addClass("collapse-parent-active");
                        $(this).parents(".nav-item").addClass("active");
                    } else {
                        $(this)
                            .parent()
                            .find(".nav-link")
                            .removeClass("collapse-parent-active");
                        $(this).parents(".nav-item").removeClass("active");
                    }
                });
            }
        },
    },
};
</script>
<style lang="scss">
.sidebar {
    min-height: calc(100vh + 460px) !important;
}
.collapse-parent-active {
    font-weight: 700;
    transition: all 0.3s ease-in-out;
}
.collapse {
    z-index: 6 !important;
    .collapse-item {
        &.router-link-exact-active {
            color: #4e73df !important;
            font-weight: 900;
        }
    }
}
.spacer-bottom {
    margin-bottom: 150px;
}
</style>
