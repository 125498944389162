<template>
    <div
        class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"
    >
        <!-- Sidebar Toggle (Topbar) -->
        <button id="sidebarToggleTop" class="btn btn-link rounded-circle mr-3">
            <i class="fa fa-bars"></i>
        </button>

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
            <li
                class="nav-item dropdown no-arrow d-sm-none"
                v-if="
                    isShowPaginationDropDown &&
                    this.$route.name === 'daily-stock'
                "
            >
                <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="searchDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <button class="btn btn-primary btn-sm">
                        <i class="fas fa-filter"></i>
                    </button>
                </a>
                <!-- Dropdown - Messages -->
                <div
                    class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                    aria-labelledby="searchDropdown"
                >
                    <!-- <form class="form-inline mr-auto w-100 navbar-search"> -->
                    <div class="form-group row mt-4 mb-0">
                        <div class="col-sm-4 mb-3 form-floating">
                            <div class="pagination-container mt-sm-1">
                                <b-form-select
                                    v-model="perPage"
                                    :options="loadRows"
                                    id="perPage"
                                ></b-form-select>
                                <label for="perPage">Set Per Page</label>
                            </div>
                        </div>
                        <div class="col-sm-4 mb-3 form-floating">
                            <div class="pagination-container">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    aria-controls="daily-stock"
                                ></b-pagination>
                                <label for="entryPagination" class="page-label"
                                    >Pagination</label
                                >
                            </div>
                        </div>
                        <div class="col-sm-4 mb-3 mb-sm-0 form-floating">
                            <div class="pagination-container">
                                <p class="page-text">
                                    {{ pageText }}
                                </p>
                                <label for="entryPageTexr">Listing Items</label>
                            </div>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>
            </li>
            <!-- <li class="nav-item dropdown no-arrow d-sm-none">
                <a
                    class="nav-link dropdown-toggle"
                    href="/admin/daily/stock#entry"
                >
                    <button class="btn btn-primary btn-sm">
                        <i class="fas fa-edit"></i>
                    </button>
                </a>
            </li> -->
            <div class="topbar-divider d-none d-sm-block"></div>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
                <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="userDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span
                        v-if="user"
                        class="mr-2 d-none d-lg-inline text-gray-600 small"
                        >{{ user.first_name }} {{ user.last_name }}</span
                    >
                    <img
                        class="img-profile rounded-circle"
                        src="img/undraw_profile.svg"
                    />
                </a>
                <!-- Dropdown - User Information -->
                <div
                    class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                    aria-labelledby="userDropdown"
                >
                    <div
                        class="dropdown-header text-uppercase text-dark"
                        v-if="user && user.outlet.length"
                    >
                        <i class="fas fa-fw fa-hotel fa-fw mr-2"></i>
                        Outlet &raquo;
                        {{
                            user && user.outlet.length > 0
                                ? user.outlet[0].name
                                : ""
                        }}
                    </div>
                    <div class="dropdown-header text-uppercase">
                        <i class="fas fa-user-tag fa-fw mr-2 text-gray-400"></i>
                        Role &raquo;
                        {{
                            user && user.roles.length > 0
                                ? user.roles[0].display_name
                                : ""
                        }}
                    </div>
                    <!-- <div class="dropdown-divider"></div> -->
                    <router-link class="dropdown-item" to="/admin/user/profile"
                        ><i
                            class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"
                        ></i>
                        Profile</router-link
                    >
                    <!-- <a class="dropdown-item" href="#">
                      <i
                          class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"
                      ></i>
                      Settings
                  </a>
                  <a class="dropdown-item" href="#">
                      <i
                          class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"
                      ></i>
                      Activity Log
                  </a> -->
                    <div class="dropdown-divider"></div>
                    <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="logout"
                        data-toggle="modal"
                        data-target="#logoutModal"
                    >
                        <i
                            class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                        ></i>
                        Logout
                    </a>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Topbar",
    computed: {
        ...mapGetters(["user"]),
        pageText() {
            if (this.items.length > 0) {
                return `From ${Math.ceil(
                    this.currentPage * this.perPage - this.perPage + 1
                )} to
                ${
                    this.items.length > this.perPage
                        ? this.perPage * this.currentPage
                        : this.items.length
                } of ${this.items.length} items (${Math.ceil(
                    this.items.length / this.perPage
                )}
                Pages)`;
            } else {
                return "No Data!";
            }
        },
    },
    data() {
        return {
            perPage: 25,
            currentPage: 1,
            loadRows: [
                { value: 25, text: "25" },
                { value: 50, text: "50" },
                { value: 100, text: "100" },
                { value: 200, text: "200" },
                { value: 300, text: "300" },
                { value: 400, text: "400" },
            ],
            isShowPaginationDropDown: false,
            items: [],
            rows: 0,
        };
    },
    methods: {
        logout() {
            localStorage.removeItem("token");
            localStorage.removeItem("roles");
            localStorage.removeItem("permissions");
            this.$store.dispatch("user", null);
            // this.$toasted.success("Logged out Successfull");

            /**
             * Clear PWA Cache on logout
             */
            caches.keys().then((cacheNames) => {
                cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                });
            });
            /**
             * Unregistrer ServiceWorker on logout
             */
            if (window.navigator && navigator.serviceWorker) {
                navigator.serviceWorker
                    .getRegistrations()
                    .then(function (registrations) {
                        for (let registration of registrations) {
                            registration.unregister();
                        }
                    });
            }
            this.$router.push("/login");
        },
    },
};
</script>
