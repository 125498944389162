import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "@/router";
// import axios from "@/axios";
import store from "@/store";
import Toasted from "vue-toasted";
import Vuelidate from "vuelidate";
import "bootstrap-vue/dist/bootstrap-vue.css";
import bootstrap from "./bootstrap";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import packageInfo from "../package.json";
import Auth from "./auth";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/sass/app.scss";
Vue.config.productionTip = false;

/**
 * @param request
 * @return response
 * Define API Base URL
 * Use it anyware as this$apiBase
 */
if (process.env.NODE_ENV === "development") {
    Vue.prototype.$apiBase = "http://localhost/liquorin/core/api";
    Vue.prototype.$dev = true;
} else if (process.env.NODE_ENV === "production") {
    // console.log = function () {};
    Vue.prototype.$apiBase = "https://liquorin.tpdb.in/core/api";
    Vue.prototype.$dev = false;
}

Vue.prototype.APP_NAME = packageInfo.name.toUpperCase();
Vue.prototype.APP_VERSION = `v${packageInfo.version}`;
Vue.prototype.$appName = packageInfo.name.toUpperCase();
Vue.prototype.$appVersion = `v${packageInfo.version}`;

Vue.prototype.$auth = new Auth(
    localStorage.getItem("vuex")
        ? JSON.parse(localStorage.getItem("vuex")).user
        : ""
);

// Vue.use(axios);
Vue.use(Toasted, {
    iconPack: "fontawesome",
});
Vue.use(bootstrap);
// Vue.use(Popper);
Vue.use(Vuelidate);
// Vue.use(new Popper());
Vue.use(VueSweetalert2);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

new Vue({
    router,
    store,
    Toasted,
    render: function (h) {
        return h(App);
    },
}).$mount("#app");
