export default class Auth {
    constructor(authUser) {
        this.user = authUser;
    }

    roles() {
        return this.user.map((role) => role.roles.name);
    }

    permissions() {
        // return this.user.permissions.map((permission) => permission.name);
    }

    isSuperAdmin() {
        console.log("authRole", this.roles);
        // return this.roles.includes("superadmin");
    }

    isAdmin() {
        // return this.roles.includes("admin");
    }

    isUser() {
        // return this.roles.includes("user");
    }

    can($permissionsName) {
        return this.permissions.includes($permissionsName);
    }
}
