import axios from "@/axios";
import Vue from "vue";

import Router from "vue-router";
import store from "../store";
import AdminLayout from "../views/admin/layout/index";

Vue.use(Router);

let router = new Router({
    mode: "hash",
    routes: [
        {
            path: "/",
            name: "home",
            component: () => import("@/views/home/index.vue"),
        },
        {
            path: "/login/:user_id?",
            name: "login",
            component: () => import("@/views/login/index.vue"),
        },
        {
            path: "/register",
            name: "register",
            component: () => import("@/views/register/index.vue"),
        },
        {
            path: "/verify/user/:id",
            name: "verify",
            props: true,
            component: () => import("@/views/verify/index.vue"),
        },
        {
            path: "/forgot-password",
            name: "forgot",
            component: () => import("@/views/forgot/index.vue"),
        },
        {
            path: "/reset/:token",
            name: "reset",
            component: () => import("@/views/reset/index.vue"),
        },
        /**
         * Admin routes
         */
        {
            path: "/admin",
            name: "admin",
            component: () => import("@/views/admin/dashboard.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Dashboard",
            },
        },
        {
            path: "/admin/masters/category",
            name: "category",
            component: () => import("@/views/admin/masters/category"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "All Category",
            },
        },
        {
            path: "/admin/masters/category/create",
            name: "category-create",
            component: () =>
                import("@/views/admin/masters/category/CreateForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Create Category",
            },
        },
        {
            path: "/admin/masters/category/edit/:id",
            name: "category-edit",
            component: () => import("@/views/admin/masters/category/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Category",
            },
        },
        {
            path: "/admin/masters/brand",
            name: "brand",
            component: () => import("@/views/admin/masters/brand"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "All Brand",
            },
        },
        {
            path: "/admin/masters/brand/create",
            name: "brand-create",
            component: () => import("@/views/admin/masters/brand/CreateForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Create Brand",
            },
        },
        {
            path: "/admin/masters/brand/edit/:id",
            name: "brand-edit",
            component: () => import("@/views/admin/masters/brand/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Brand",
            },
        },
        {
            path: "/admin/masters/supplier",
            name: "supplier",
            component: () => import("@/views/admin/masters/supplier"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "All Supplier",
            },
        },
        {
            path: "/admin/masters/supplier/create",
            name: "supplier-create",
            component: () =>
                import("@/views/admin/masters/supplier/CreateForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Create Supplier",
            },
        },
        {
            path: "/admin/masters/supplier/edit/:id",
            name: "supplier-edit",
            component: () => import("@/views/admin/masters/supplier/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Supplier",
            },
        },
        {
            path: "/admin/masters/taxes",
            name: "taxes",
            component: () => import("@/views/admin/masters/taxes"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "All Taxes",
            },
        },
        {
            path: "/admin/masters/taxes/create",
            name: "tax-create",
            component: () => import("@/views/admin/masters/taxes/CreateForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Create Tax",
            },
        },
        {
            path: "/admin/masters/taxes/edit/:id",
            name: "tax-edit",
            component: () => import("@/views/admin/masters/taxes/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Tax",
            },
        },
        {
            path: "/admin/masters/items",
            name: "master-item",
            component: () => import("@/views/admin/masters/items"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "All Item Master",
            },
        },
        {
            path: "/admin/masters/items/create",
            name: "master-item-create",
            component: () => import("@/views/admin/masters/items/CreateForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Create Master Item",
            },
        },
        {
            path: "/admin/masters/items/edit/:id",
            name: "master-item-edit",
            component: () => import("@/views/admin/masters/items/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Master Item",
            },
        },
        {
            path: "/admin/company",
            name: "companies",
            component: () => import("@/views/admin/companies/index"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "All Companies",
            },
        },
        {
            path: "/admin/company/create",
            name: "company-create",
            component: () => import("@/views/admin/companies/CreateForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Create Company",
            },
        },
        {
            path: "/admin/company/edit/:id",
            name: "company-edit",
            component: () => import("@/views/admin/companies/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Company",
            },
        },
        {
            path: "/admin/company/outlet",
            name: "outlets",
            component: () => import("@/views/admin/companies/outlets"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Outlets",
            },
        },
        {
            path: "/admin/company/outlet/create",
            name: "outlet",
            component: () =>
                import("@/views/admin/companies/outlets/CreateForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Create Outlet",
            },
        },
        {
            path: "/admin/company/outlet/edit/:id",
            name: "outlet-edit",
            component: () => import("@/views/admin/companies/outlets/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Outlet",
            },
        },
        {
            path: "/admin/outlet/items",
            name: "outlet-item",
            component: () => import("@/views/admin/companies/outlets/items"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "All Outlet Items",
            },
        },
        {
            path: "/admin/outlet/items/create",
            name: "outlet-item-create",
            component: () =>
                import("@/views/admin/companies/outlets/items/CreateForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Create Outlet Item",
            },
        },
        {
            path: "/admin/outlet/items/edit/:id",
            name: "outlet-item-edit",
            component: () =>
                import("@/views/admin/companies/outlets/items/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Outlet Item",
            },
        },
        {
            path: "/admin/outlet/items/group/edit/",
            name: "outlet-item-group-edit",
            component: () =>
                import("@/views/admin/companies/outlets/items/GroupUpdate"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Group Update Outlet Item",
            },
        },
        {
            path: "/admin/users",
            name: "users",
            component: () => import("@/views/admin/users/index"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Users",
            },
        },
        {
            path: "/admin/users/access-tokens",
            name: "users-access-tokens",
            component: () => import("@/views/admin/users/tokens/index"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Users Access Tokens",
            },
        },
        {
            path: "/admin/user/profile",
            name: "profile",
            component: () => import("@/views/admin/users/profile/"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Profile",
            },
        },
        {
            path: "/admin/user/profile/edit",
            name: "edit-profile",
            component: () => import("@/views/admin/users/profile/edit"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Profile",
            },
        },
        {
            path: "/admin/users/create",
            name: "create-user",
            component: () => import("@/views/admin/users/CreateForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Create Users",
            },
        },
        {
            path: "/admin/users/edit/:id",
            name: "edit-user",
            component: () => import("@/views/admin/users/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Edit Users",
            },
        },
        {
            path: "/admin/daily/stock",
            name: "daily-stock",
            component: () => import("@/views/admin/stock/daily"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Daily Stock",
            },
        },
        {
            path: "/admin/daily/stock/v111",
            name: "daily-stock-v111",
            component: () =>
                import("@/views/admin/stock/daily/index-v1.0.9.vue"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Daily Stock v1.1.1",
            },
        },
        {
            path: "/admin/daily/stock/entry",
            name: "stock-entry",
            component: () => import("@/views/admin/stock/daily"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Daily Stock",
            },
        },
        {
            path: "/admin/daily/stock/update",
            name: "stock-update",
            component: () => import("@/views/admin/stock/daily/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Update Daily Stock",
            },
        },
        {
            path: "/admin/stock/summary",
            name: "stock-summary",
            component: () => import("@/views/admin/stock/summary"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Stock Summary",
            },
        },
        {
            path: "/admin/stock/summary/update",
            name: "stock-summary-update",
            component: () => import("@/views/admin/stock/summary/EditForm"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Stock Summary",
            },
        },
        {
            path: "/admin/report/datewise",
            name: "datewise-purchase-sales",
            component: () => import("@/views/admin/reports/datewise"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Date Wise Purchases & Sales",
            },
        },
        {
            path: "/admin/report/stock-in-liters",
            name: "closing-stock-liters",
            component: () => import("@/views/admin/reports/stockInLiters"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Closing Stock in Liters",
            },
        },
        {
            path: "/admin/report/consumption-in-liters",
            name: "consumption-in-liters",
            component: () =>
                import("@/views/admin/reports/salesConsumptionLiter"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Sales Consumption in Liters",
            },
        },
        {
            path: "/admin/report/stock-in-out",
            name: "stock-in-out",
            component: () => import("@/views/admin/reports/stockInOut"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Stock In & Out",
            },
        },
        {
            path: "/admin/report/sales-stock-statement",
            name: "sales-stock-statement",
            component: () => import("@/views/admin/reports/saleStockStatement"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Sales & Stock Statement",
            },
        },
        {
            path: "/admin/settings",
            name: "outlet-settings",
            component: () => import("@/views/admin/settings"),
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Outlet Settings",
            },
        },
        {
            path: "/:pathMatch(.*)*",
            name: "NotFound",
            component: (resolve) => {
                require(["@/views/admin/page-not-found"], resolve);
            },
            meta: {
                requiresAuth: true,
                layout: AdminLayout,
                title: "Liquorin | Page Not Found!",
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (to.fullPath !== "/login") {
            axios
                .post("/check/auth/token")
                // .then((res) => {
                //     console.log("Auth res", res);
                // })
                .catch((error) => {
                    console.log("Auth Error", error);
                    console.log("Auth Error response", error.response);
                    if (error && error.response.status === 401) {
                        console.log("Authentication required!");
                        setTimeout(() => {
                            localStorage.removeItem("token");
                            localStorage.removeItem("roles");
                            localStorage.removeItem("permissions");
                            // Clear PWA Cache on Logout
                            caches.keys().then((cacheNames) => {
                                cacheNames.forEach((cacheName) => {
                                    caches.delete(cacheName);
                                });
                            });
                            // Also Unregister ServiceWorker on Logout
                            if (window.navigator && navigator.serviceWorker) {
                                navigator.serviceWorker
                                    .getRegistrations()
                                    .then(function (registrations) {
                                        for (let registration of registrations) {
                                            registration.unregister();
                                        }
                                    });
                            }
                            store.dispatch("user", null);
                            Vue.swal.close();
                            Vue.toasted
                                .show("Please Login to continue!")
                                .goAway(6000);
                            setTimeout(() => {
                                router.push("/login");
                            }, 500);
                        }, 2500);
                    }
                });
        }
        document.title = to.meta.title || "LiquorIn";
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        if (store.getters.user) {
            next();
            return;
        }
        next("/login");
        document.title = "Liquorin | Login";
    } else {
        next();
    }
});

export default router;
